import {API} from '../types';

type EditProfileParams = {
  id: number;
  name: string;
  email?: string;
  role: string;
  phone?: string;
  centreIds?: number[];
};

type EditProfilePayloads = {
  id?: number;
  name: string;
  email?: string;
  role: string;
  phone?: string;
  centreIds?: number[];
};

interface Response {
  success: boolean;
}

export function editProfileAPI(params: EditProfileParams): API<Response> {
  const payload: EditProfilePayloads = {...params};
  delete payload.id;
  return {
    getData: async client => {
      const {data} = await client({
        url: `/profiles/${params.id}`,
        method: 'PUT',
        data: payload,
      });
      return {data: data};
    },
    getMockData: () => {
      return {success: false};
    },
  };
}

export function updateResignedAPI(params: {
  resignedAt: string;
  id: string;
}): API<Response> {
  const payload = {...params};
  return {
    getData: async client => {
      const {data} = await client({
        url: `/profiles/${params.id}`,
        method: 'PUT',
        data: {resignedAt: payload.resignedAt, role: 'RS'},
      });
      return {data: data};
    },
    getMockData: () => {
      return {success: false};
    },
  };
}
