import {useEffect, useMemo, useRef, useState} from 'react';
import {Column} from 'react-table';
import {
  Button,
  Input,
  Modal,
  PcfDatePicker,
  Select,
  Table,
} from '../../../components';
import {createSkillOptions, locationOptions} from '../../../services/helpers';
import {strings} from '../../../services/language';
import {Profile, Qualification, Skill} from '../../../services/models';
import {formatISOString} from '../../../services/utils';
import styles from './General.module.scss';
import {updateResignedAPI, useAPI} from '../../../services/api';
import {useLoading} from '../../../services/hooks';

type Props = {
  profile: Profile;
  skills: Skill[];
};

export default function General({profile, skills}: Props) {
  const [resignedAt, setResignedAt] = useState('');
  const resultModalRef = useRef<any>(null);
  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);
  const districtsText = useMemo(() => {
    let text = '';
    profile.districtNumbers?.forEach((d, i, a) => {
      const district = locationOptions.filter(l => l.value === d);
      if (district.length > 0) {
        text += district[0].content + '.' + (i === a.length - 1 ? '' : '\n');
      }
    });
    return text;
  }, [profile]);

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: any[] = useMemo(
    () => createRowsData(profile.qualifications),
    [profile.qualifications]
  );

  const submitResignedAt = useAPI(() => {
    return updateResignedAPI({
      resignedAt: resignedAt,
      id: profile.id.toString(),
    });
  });

  useEffect(() => {
    if (submitResignedAt.data?.success) {
      resultModalRef.current.showModal();
    }
  }, [submitResignedAt.data]);

  useEffect(() => {
    if (profile?.resignedAt) {
      setResignedAt(profile.resignedAt);
    }
  }, [profile?.resignedAt]);

  useLoading(submitResignedAt.loading);

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.inputItem}>
          <Select
            topLabel={strings('Role')}
            disabled
            border
            defaultLabel={strings('Select Role')}
            value={profile.skillId}
            optionList={skillOptions}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="email"
            disabled
            value={profile.email}
            type="text"
            handleChange={() => {}}
            label={strings('Email Address')}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="contactNumberexpiry"
            disabled
            value={profile.phone}
            type="text"
            handleChange={() => {}}
            label={strings('Contact Number')}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="expiry"
            disabled
            value={
              profile.medicalReportDate
                ? formatISOString(profile.medicalReportDate, 'DD/MM/YY')
                : ''
            }
            type="text"
            handleChange={() => {}}
            label={strings('Medical Report Date')}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="districts"
            disabled
            value={districtsText}
            type="textarea"
            handleChange={() => {}}
            label={
              strings('Notification Preferences') + ' - ' + strings('Districts')
            }
          />
        </div>
        <PcfDatePicker
          label={strings('Resigned Date')}
          placeholder={strings('Select Dates')}
          type="single"
          clearButton={false}
          value={new Date(resignedAt || '')}
          onSelectDate={selectedDate => {
            setResignedAt(selectedDate || '');
          }}
        />
        <Button
          disabled={
            submitResignedAt.loading ||
            !resignedAt ||
            resignedAt === profile.resignedAt
          }
          label={strings('Save')}
          type="primary"
          handleClick={() => submitResignedAt.request()}
        />
      </div>

      <Modal
        ref={resultModalRef}
        body={
          <div>
            <div>Update Resigned Date Success</div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Done')}
              type="danger"
              handleClick={() => {
                resultModalRef.current.hideModal();
              }}
            />
          </>
        }
        showClose={false}
      />
      {profile.qualifications && (
        <div className={styles.qualificationsWrapper}>
          <div className={styles.label}>{strings('Qualifications')}</div>
          <Table columns={columns} data={data} />
        </div>
      )}
    </div>
  );
}

function createColumns() {
  return [
    {
      Header: '',
      accessor: 'index',
    },
    {
      Header: strings('Title'),
      accessor: 'title',
    },
  ];
}

function createRowsData(qualifications: Qualification[] | undefined): any[] {
  return qualifications
    ? qualifications.map((q, index) => ({
        index: <span className={styles.greyText}>{index + 1}</span>,
        title: q.name,
      }))
    : [];
}
