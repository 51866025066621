import styles from './StaffList.module.scss';
import {Table, Select, Button, Badge, CheckBox} from '../../../components';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Cell, Column, TableInstance} from 'react-table';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as WorkIcon} from '../../../assets/img/work.svg';
import {ReactComponent as DocumentIcon} from '../../../assets/img/document.svg';
import {ReactComponent as SortIcon} from '../../../assets/img/sort.svg';
import {strings} from '../../../services/language';
import {useHistory} from 'react-router-dom';
import {Profile, Skill} from '../../../services/models';
import {createSkillOptions} from '../../../services/helpers';
import {getReliefStaffsAPI, useAPI, useResult} from '../../../services/api';
import {useLoading, useSelect} from '../../../services/hooks';
import moment from 'moment';
import {downloadCsv, formatISOString} from '../../../services/utils';
import {exportRsAPI} from '../../../services/api/apis/exportRSAPI';

type StaffListProps = {skills: Skill[]};

interface StaffData {
  user: JSX.Element;
  role: number | undefined;
  expiry: JSX.Element;

  userName: string;
  userId: number;

  button: JSX.Element;
}

const _limit = 8;

export default function StaffList({skills}: StaffListProps) {
  const [staffList, setStaffList] = useState<Profile[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [total, setTotal] = useState(0);
  const [skill, setSkill] = useState<number[]>([]);
  const [expiry, setExpiry] = useState('');
  const {states: sortByRule, bind: bindSortByRule} = useSelect(
    'medicalExpiredAt:ASC'
  );

  const getStaffsApi = useAPI(pageIndex =>
    getReliefStaffsAPI({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: sortByRule.value,
      ...(searchValue && {query: searchValue}),
      ...(skill.length > 0 && {skillIds: skill}),
      ...(expiry && {medicalExpiredAt_lt: expiry}),
    })
  );

  useEffect(() => {
    setManualFilterSort([skill, expiry, sortByRule.value]);
  }, [skill, expiry, sortByRule.value]);

  const fetchData = useCallback((pageIndex: number) => {
    if (!getStaffsApi.loading) {
      setCurrentPageIndex(pageIndex);
      getStaffsApi.request(pageIndex);
    }
  }, []);

  const exportRSStaffsApi = useAPI(exportRsAPI);

  function exportPCFStaffs() {
    exportRSStaffsApi.request({
      _start: currentPageIndex * _limit,
      _limit: _limit,
      _sort: sortByRule.value,
      ...(searchValue && {query: searchValue}),
      ...(skill.length > 0 && {skillIds: skill.join(',')}),
      ...(expiry && {medicalExpiredAt_lt: expiry}),
      export_csv: true,
    });
  }

  useResult(exportRSStaffsApi.data, data => {
    if (data.content) {
      downloadCsv(data.filename, data.content);
    }
  });

  useLoading(getStaffsApi.loading || exportRSStaffsApi.loading);

  useResult(getStaffsApi.data, data => {
    setStaffList(data.list);
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  const history = useHistory();
  const columns: Column[] = useMemo(() => createColumns(skills), [skills]);
  const data: StaffData[] = useMemo(
    () => createRowsData(history, staffList),
    [staffList]
  );

  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);
  const sortOptions = useMemo(
    () => [
      {
        content: strings('Medical Report Date'),
        value: 'medicalExpiredAt:ASC',
      },
      {
        content: strings('Alphabetical'),
        value: 'name:ASC',
      },
    ],
    []
  );
  const expiryOptions = useMemo(
    () => [
      {
        content: strings('Next %0 month', 1),
        value: moment().add(1, 'month').toISOString(),
      },
      {
        content: strings('Next %0 months', 3),
        value: moment().add(3, 'month').toISOString(),
      },
      {
        content: strings('Next %0 months', 6),
        value: moment().add(6, 'month').toISOString(),
      },
      {
        content: strings('No Expiry'),
        value: 'null',
      },
    ],
    []
  );

  const hiddenColumns = ['userName', 'userId', 'date'];

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="searchQuery"
              id="searchQuery"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={strings('Search Name or Employee Code')}
            />
          </div>
          <span className={styles.numberRecord}>{`${total} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <Select
              fixedLabel={
                <WorkIcon
                  style={{
                    stroke: 'var(--blue-accent)',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              defaultLabel={strings('Roles')}
              optionList={skillOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => {
                setSkill(values);
              }}
            />
            <Select
              fixedLabel={<DocumentIcon />}
              defaultLabel={strings('Medical Report Date')}
              optionList={expiryOptions}
              allItem
              onSelectValue={values => {
                setExpiry(values[0]);
              }}
            />
            <div className={styles.sortWrapper}>
              <Select
                fixedLabel={<SortIcon />}
                optionList={sortOptions}
                {...bindSortByRule}
              />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              type="outline"
              label={strings('Export')}
              handleClick={exportPCFStaffs}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        rowClick
        pageInput
        hiddenColumns={hiddenColumns}
        manualQuery={searchValue}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}

function createColumns(skills: Skill[]) {
  return [
    {
      Header: strings('User'),
      accessor: 'user',
    },
    {
      Header: strings('Primary Role'),
      accessor: 'role',
      Cell: (props: TableInstance & Cell) => {
        const staffSkill = skills.filter(skill => skill.id === props.value);
        return (
          props.value &&
          staffSkill.length > 0 && (
            <Badge type={'skill'} kind="skillIconText" skill={staffSkill[0]} />
          )
        );
      },
      filter: 'selectOptions',
    },
    {
      Header: strings('Medical Report Date'),
      accessor: 'expiry',
    },
    {
      Header: strings('Resigned Date'),
      accessor: 'resignedAt',
    },
    {
      Header: '',
      accessor: 'userName',
    },
    {
      Header: '',
      accessor: 'userId',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createRowsData(history: any, staffList: Profile[]): StaffData[] {
  return staffList.map(profile => ({
    user: (
      <div className={styles.userCell}>
        <div
          className={styles.avatar}
          style={{
            backgroundImage: profile.photo ? `url(${profile.photo})` : 'none',
          }}
        ></div>
        <div className={styles.userRight}>
          <div>{profile.name}</div>
          <div className={styles.greyText}>{profile.employeeCode || ''}</div>
        </div>
      </div>
    ),
    role: profile.skillId,
    expiry: (
      <div>
        {profile.medicalReportDate
          ? formatISOString(profile.medicalReportDate, 'DD/MM/YY')
          : ''}
      </div>
    ),
    resignedAt: (
      <div>
        {profile.resignedAt
          ? formatISOString(profile.resignedAt, 'DD/MM/YY')
          : ''}
      </div>
    ),
    userName: profile.name,
    userId: profile.id,
    button: (
      <div
        className={styles.buttonCell}
        onClick={e => {
          history.push('/relief-staffs/' + profile.id);
          if (e) {
            e.stopPropagation();
          }
        }}
      >
        <ChevronIcon
          style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
        />
      </div>
    ),
  }));
}
