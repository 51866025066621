import {Centre} from '../../models';
import {API} from '../types';
import {getState} from '../../store';

interface Response {
  list: Centre[];
  lastSync: string;
}

export function getCentresAPI(): API<Response> {
  const now = new Date().toISOString();

  return {
    getData: async client => {
      const {data} = await client({
        url: '/centres',
        method: 'GET',
        params: {
          updatedAt_gt: getState().centres.lastSync,
        },
      });
      const centres: Centre[] = data.list;
      return {
        data: {
          list: getState()
            .centres.list.filter(c1 => !centres.some(c2 => c2.id == c1.id))
            .concat(centres)
            .sort((c1, c2) => c1.id - c2.id),
          lastSync: now,
        },
      };
    },
    getMockData: () => {
      return {list: [], lastSync: now};
    },
  };
}

export function createCentreAPI({centre}: {centre: Centre}): API<any> {
  const now = new Date().toISOString();
  const {id, totalReviews, createdAt, updatedAt, ...rest} = centre;

  return {
    getData: async client => {
      const {data} = await client({
        url: '/centres',
        method: 'POST',
        data: {
          ...rest,
        },
      });
      return {data};
    },
    getMockData: () => {
      return {list: [], lastSync: now};
    },
  };
}

export function updateCentreAPI({centre}: {centre: Centre}): API<any> {
  const now = new Date().toISOString();
  const {
    createdAt,
    updatedAt,
    url,
    id,
    totalReviews,
    dgbsc,
    districtGroup,
    ...rest
  } = centre;

  return {
    getData: async client => {
      const {data} = await client({
        url: `/centres/${id}`,
        method: 'PUT',
        data: {
          ...rest,
        },
      });
      return {data};
    },
    getMockData: () => {
      return {list: [], lastSync: now};
    },
  };
}

export function getCentreByIdAPI({id}: {id: string}): API<Centre | undefined> {
  return {
    getData: async client => {
      const {data} = await client({
        url: `/centres/${id}`,
        method: 'GET',
      });
      return {data};
    },
    getMockData: () => {
      return undefined;
    },
  };
}
