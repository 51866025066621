import styles from './StaffList.module.scss';
import {Table, Select, Button, Modal} from '../../../components';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Cell, Column, TableInstance} from 'react-table';
import {ReactComponent as MoreIcon} from '../../../assets/img/more.svg';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as WorkIcon} from '../../../assets/img/work.svg';
import {ReactComponent as SortIcon} from '../../../assets/img/sort.svg';
import {ReactComponent as EditIcon} from '../../../assets/img/edit.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/location.svg';
import {ReactComponent as TrashIcon} from '../../../assets/img/trash.svg';
import {strings} from '../../../services/language';
import {useHistory} from 'react-router-dom';
import {downloadCsv, formatISOString} from '../../../services/utils';
import {toRoute} from '../../../services/router';
import {staffRoles, staffRoleOptions} from '../../../services/helpers';
import {
  deleteProfileAPI,
  exportPCFStaffsAPI,
  getPCFStaffsAPI,
  useAPI,
  useResult,
} from '../../../services/api';
import {Centre, Profile} from '../../../services/models';
import {
  useLoading,
  useMultipleSelect,
  useSelect,
  useSyncCentres,
} from '../../../services/hooks';
import ReactMarkdown from 'react-markdown';

type StaffListProps = {};

interface StaffData {
  user: JSX.Element;
  role: string;
  centres: JSX.Element;
  dateCreated: string;

  userName: string;
  userId: number;
  date: Date;

  button: JSX.Element;
}

const _limit = 8;

export default function StaffList({}: StaffListProps) {
  const [staffList, setStaffList] = useState<Profile[]>([]);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showMores, setShowMores] = useState<{[key: string]: boolean}>(() => {
    let obj = {};
    staffList.forEach((staff, index) => {
      obj = {...obj, [index]: false};
    });
    return obj;
  });
  const [selectedStaff, setSelectedStaff] = useState<{
    id?: number;
    name?: string;
  }>({});
  const [searchValue, setSearchValue] = useState('');
  const [total, setTotal] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [role, setRole] = useState<string[]>([]);
  const {states: sortByRule, bind: bindSortByRule} = useSelect('name:ASC');
  const {states: centre, bind: bindCentre} = useMultipleSelect();
  const {centres} = useSyncCentres();

  const centreList = useMemo(
    () =>
      centres.map(centre => ({
        content: centre.name,
        value: centre.id,
        text: centre.name,
      })),
    centres
  );

  const deleteModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);

  const getStaffsApi = useAPI(pageIndex =>
    getPCFStaffsAPI({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: sortByRule.value,
      ...(searchValue && {query: searchValue}),
      ...(role.length > 0 && {roles: role}),
      ...(centre.value.length > 0 && {centreIds: centre.value}),
    })
  );
  const deleteStaffApi = useAPI(deleteProfileAPI);
  const exportPCFStaffsApi = useAPI(exportPCFStaffsAPI);

  function exportPCFStaffs() {
    exportPCFStaffsApi.request({
      _start: 0,
      _limit: _limit,
      _sort: sortByRule.value,
      ...(searchValue && {query: searchValue}),
      ...(role.length > 0 && {roles: role}),
      export_csv: true,
    });
  }

  useResult(exportPCFStaffsApi.data, data => {
    if (data.content) {
      downloadCsv(data.filename, data.content);
    }
  });

  useEffect(() => {
    setManualFilterSort([role, sortByRule.value]);
  }, [role, sortByRule.value, centre.value]);

  const fetchData = useCallback((pageIndex: number) => {
    if (!getStaffsApi.loading) {
      getStaffsApi.request(pageIndex);
    }
  }, []);

  useLoading(
    getStaffsApi.loading || deleteStaffApi.loading || exportPCFStaffsApi.loading
  );

  useResult(getStaffsApi.data, data => {
    setStaffList(data.list);
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  useEffect(() => {
    if (
      (deleteStaffApi.data?.success || deleteStaffApi.error) &&
      !deleteStaffApi.loading
    ) {
      if (deleteStaffApi.data?.success && selectedStaff.id) {
        const newStaffList = staffList.filter(
          profile => profile.id !== selectedStaff.id
        );
        setStaffList(newStaffList);
      }
      resultModalRef.current.showModal();
    }
  }, [deleteStaffApi.loading]);

  useEffect(() => {
    let obj = {};
    staffList.forEach((schedule, index) => {
      obj = {...obj, [index]: false};
    });
    setShowMores(obj);
  }, [staffList]);

  const history = useHistory();
  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: StaffData[] = useMemo(
    () =>
      createRowsData(
        history,
        staffList,
        showMores,
        setShowMores,
        setSelectedStaff,
        deleteModalRef,
        centres,
        showToolTip,
        setShowToolTip
      ),
    [showMores, staffList, showToolTip]
  );
  const sortList = useMemo(
    () => [
      {
        content: strings('Date Created'),
        value: 'createdAt:DESC',
      },
      {
        content: strings('Alphabetical'),
        value: 'name:ASC',
      },
    ],
    []
  );

  const hiddenColumns = ['userName', 'userId', 'date'];

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="searchQueryPCFStaff"
              id="searchQueryPCFStaff"
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              readOnly={getStaffsApi.loading}
              placeholder={strings('Search Name or Employee Code')}
            />
          </div>
          <span className={styles.numberRecord}>{`${total} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <Select
              fixedLabel={
                <WorkIcon
                  style={{
                    stroke: 'var(--blue-accent)',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              defaultLabel={strings('Roles')}
              optionList={staffRoleOptions}
              divider
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => {
                setRole(values);
              }}
            />
            <Select
              defaultLabel={strings('Centres')}
              fixedLabel={<LocationIcon />}
              divider
              searchable
              multipleSelect
              allToggle
              allItem
              optionList={centreList}
              {...bindCentre}
            />
            <div className={styles.sortWrapper}>
              <Select
                fixedLabel={<SortIcon />}
                optionList={sortList}
                {...bindSortByRule}
              />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              type="outline"
              label={strings('Export')}
              handleClick={exportPCFStaffs}
            />
            <Button
              label={strings('Create')}
              handleClick={() =>
                history.push(toRoute('/pcf-staffs/create-pcf-staff-profile'))
              }
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        rowClick
        pageInput
        hiddenColumns={hiddenColumns}
        manualQuery={searchValue}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
      <Modal
        ref={deleteModalRef}
        header={<h2>{strings('Delete Profile?')}</h2>}
        body={
          <div>
            <div>
              {
                <ReactMarkdown
                  children={strings(
                    'You are deleting staff ‘%0’.',
                    `**${selectedStaff.name}**`
                  )}
                />
              }
            </div>
            <br />
            <div>{strings('This action cannot be undone.')}</div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => deleteModalRef.current.hideModal()}
            />
            <Button
              label={strings('Delete')}
              type="danger"
              handleClick={() => {
                selectedStaff.id && deleteStaffApi.request(selectedStaff.id);
                deleteModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
      <Modal
        ref={resultModalRef}
        showClose={false}
        body={
          <div>
            <div>
              {deleteStaffApi.error
                ? deleteStaffApi.error
                : deleteStaffApi.data?.success
                ? strings('Delete Profile Successfully')
                : ''}
            </div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Done')}
              type="danger"
              handleClick={() => {
                resultModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
    </div>
  );
}

function createColumns() {
  return [
    {
      Header: strings('User'),
      accessor: 'user',
    },
    {
      Header: strings('Role'),
      accessor: 'role',
      Cell: (props: TableInstance & Cell) => (
        <div className={styles.userRight}>
          <div>{staffRoles[props.value]}</div>
          <div className={styles.greyText}>{props.value}</div>
        </div>
      ),
    },
    {
      Header: strings('Centres'),
      accessor: 'centres',
    },
    {
      Header: strings('Date Created'),
      accessor: 'dateCreated',
    },
    {
      Header: strings('Resigned Date'),
      accessor: 'resignedAt',
      Cell: (props: TableInstance & Cell) => (
        <div>{props.value ? props.value : '_'}</div>
      ),
    },
    {
      Header: '',
      accessor: 'userName',
    },
    {
      Header: '',
      accessor: 'userId',
    },
    {
      Header: '',
      accessor: 'date',
      sortType: 'datetime',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createRowsData(
  history: any,
  staffList: Profile[],
  showMores: {[key: string]: boolean},
  setShowMores: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >,
  setSelectedStaff: React.Dispatch<
    React.SetStateAction<{
      id?: number | undefined;
      name?: string | undefined;
    }>
  >,
  deleteModalRef: React.MutableRefObject<any>,
  centres: Centre[],
  showToolTip: boolean,
  setShowToolTip: React.Dispatch<React.SetStateAction<boolean>>
): StaffData[] {
  return staffList.map((profile, index) => {
    const staffCentres = centres.filter(
      centre => profile.centreIds && profile.centreIds.includes(centre.id)
    );
    const staffCentresText = staffCentres.map(c => c.name).join(',\n');
    const disabledDelete =
      profile.role === 'CP' &&
      ((profile.resignedAt && new Date(profile.resignedAt) > new Date()) ||
        !profile.resignedAt);

    return {
      user: (
        <div className={styles.userCell}>
          <div
            className={styles.avatar}
            style={{
              backgroundImage: profile.photo ? `url(${profile.photo})` : 'none',
            }}
          ></div>
          <div className={styles.userRight}>
            <div>{profile.name}</div>
            <div className={styles.greyText}>{profile.employeeCode || ''}</div>
          </div>
        </div>
      ),
      role: profile.role,
      centres: (
        <div className={styles.breakSpace} title={staffCentresText}>
          {staffCentresText}
        </div>
      ),
      dateCreated: formatISOString(profile.createdAt, 'DD/MM/YY'),
      resignedAt: profile.resignedAt
        ? formatISOString(profile.resignedAt, 'DD/MM/YY')
        : '_',
      userName: profile.name,
      userId: profile.id,
      date: new Date(profile.createdAt),
      button: (
        <div
          className={styles.buttonCell}
          onClick={e => {
            history.push('/pcf-staffs/' + profile.id);
            if (e) {
              e.stopPropagation();
            }
          }}
        >
          <div
            className={styles.moreToggle}
            tabIndex={0}
            onBlur={() => {
              setShowMores({...showMores, [index]: false});
            }}
            onClick={e => {
              setShowMores({...showMores, [index]: !showMores[index]});
              if (e) {
                e.stopPropagation();
              }
            }}
          >
            <MoreIcon />
            {showMores[index] && (
              <div className={styles.moreDropdown}>
                <div
                  className={styles.moreButton}
                  onClick={() => history.push('/pcf-staffs/' + profile.id)}
                >
                  <EditIcon /> {strings('Edit Profile')}
                </div>
                <div
                  className={`${styles.moreButton} `}
                  onMouseEnter={() => {
                    setShowToolTip(true);
                  }}
                  onMouseLeave={() => {
                    setShowToolTip(false);
                  }}
                  onClick={() => {
                    if (disabledDelete) return;
                    setSelectedStaff({id: profile.id, name: profile.name});
                    deleteModalRef.current.showModal();
                  }}
                >
                  <span
                    className={`${styles.txt} ${
                      disabledDelete ? styles.disabled : ''
                    }`}
                  >
                    <TrashIcon /> {strings('Delete PCF Staff')}
                  </span>
                  {showToolTip && disabledDelete && (
                    <div className={styles.tooltipContent}>
                      <span>
                        Unable to delete this CP because the user either has not
                        resigned or resigned date is in the future
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    };
  });
}
