import styles from './CentreList.module.scss';
import {Table, Select, Button} from '../../../components';
import {useMemo, useState} from 'react';
import {Column} from 'react-table';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/location.svg';
import {strings} from '../../../services/language';
import {useHistory} from 'react-router-dom';
import {Centre} from '../../../services/models';
import {districtOptions, locationOptions} from '../../../services/helpers';
import {exportCentresAPI, useAPI, useResult} from '../../../services/api';
import {useLoading} from '../../../services/hooks';
import {downloadCsv} from '../../../services/utils';

type CentreListProps = {centres: Centre[]};

interface CentreData {
  centre: string;
  reviewsNo: number;
  district: string;
  address: string;
  location: string;
  locationNumber: number;
  button: JSX.Element;
}

export default function CentreList({centres}: CentreListProps) {
  const [searchValue, setSearchValue] = useState('');
  const [selectFilters, setSelectFilters] = useState<{
    [key: string]: string | (string | number)[];
  }>({district: [], locationNumber: []});
  const [recordNumber, setRecordNumber] = useState(0);

  const searchFilters = ['centre'];
  const history = useHistory();
  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: CentreData[] = useMemo(
    () => createRowsData(centres, history),
    [centres]
  );
  const hiddenColumns = ['locationNumber'];

  const exportCentresApi = useAPI(exportCentresAPI);

  function exportCentres() {
    exportCentresApi.request({export_csv: true});
  }

  useResult(exportCentresApi.data, data => {
    if (data.content) {
      downloadCsv(data.filename, data.content);
    }
  });

  useLoading(exportCentresApi.loading);

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="searchQueryCentre"
              id="searchQueryCentre"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={strings('Search Centres')}
            />
          </div>
          <span className={styles.numberRecord}>{`${recordNumber} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <Select
              fixedLabel={<LocationIcon />}
              defaultLabel={strings('District')}
              optionList={districtOptions}
              searchable
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => {
                setSelectFilters({...selectFilters, district: values});
              }}
            />
            <Select
              fixedLabel={<LocationIcon />}
              defaultLabel={strings('General Locations')}
              optionList={locationOptions}
              searchable
              searchKey={'content'}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => {
                setSelectFilters({...selectFilters, locationNumber: values});
              }}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              type="primary"
              label="New Centre"
              handleClick={() => history.push('/centres/new')}
            />
            <Button
              type="outline"
              label={strings('Export')}
              handleClick={exportCentres}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        hiddenColumns={hiddenColumns}
        rowClick
        pageInput
        filters={searchFilters}
        filterQuery={searchValue}
        columnsFilters={selectFilters}
        onChangeRowLength={rowLength => setRecordNumber(rowLength)}
      />
    </div>
  );
}

function createColumns() {
  return [
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('No. of Reviews'),
      accessor: 'reviewsNo',
    },
    {
      Header: strings('District'),
      accessor: 'district',
      filter: 'selectOptions',
    },
    {
      Header: strings('Address'),
      accessor: 'address',
    },
    {
      Header: strings('General Location'),
      accessor: 'location',
    },
    {
      Header: '',
      accessor: 'locationNumber',
      filter: 'selectOptions',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createRowsData(centres: Centre[], history: any): CentreData[] {
  return centres.map(centre => ({
    centre: centre.name,
    reviewsNo: centre.totalReviews || 0,
    district: centre.districtGroup,
    address: centre.address,
    location: locationOptions.filter(
      l => l.value === centre.districtNumber
    )?.[0]?.content,
    locationNumber: centre.districtNumber,
    button: (
      <div
        className={styles.buttonCell}
        onClick={e => {
          history.push('/centres/' + centre.id);
          if (e) {
            e.stopPropagation();
          }
        }}
      >
        <ChevronIcon
          style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
        />
      </div>
    ),
  }));
}
